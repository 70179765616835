@import '../../styles/common/common.scss';

.section {
    position: relative;
    min-height: rem(750px);
    display: flex;
    overflow: hidden;

    @include bp(xl) {
        min-height: rem(600px);
    }

    .triangle_version {
        &::after {
            display: none;
            position: absolute;
            content: '';
            bottom: 0;
            display: none;
            right: -1px;
            width: 100%;
            height: 100%;
            transform: skew(-24deg) translateX(100%);
            transform-origin: top left;
            background-color: inherit;
        }
        &.triangle_main_color::after {
            background-color: var(--utmb-color-primary);
        }

        &.img_wrapper_left::after {
            transform-origin: bottom right;
            transform: skew(-24deg) translateX(-100%);
        }

        @include bp(xl) {
            &::after {
                display: block;
            }
        }
    }

    .db_colored_bg {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;

        @include bp(xl) {
            width: 50%;
        }

        &_left {
            right: 0;
            position: absolute;

            &::after {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transform: skew(-24deg);
                transform-origin: top left;
                background-color: inherit;
            }
        }

        &_right {
            left: 0;
            position: absolute;

            &::after {
                position: absolute;
                content: '';
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                transform: skew(-24deg);
                transform-origin: bottom right;
                background-color: inherit;
            }
        }
    }

    .img_wrapper {
        position: absolute;
        z-index: -1;
        top: 0;
        display: none;

        @include bp(xl) {
            display: block;
            height: 100%;
            width: 50%;
        }
    }
    .img_wrapper_left {
        left: 0;
    }

    .img_wrapper_right {
        right: 0;
    }

    .db_over_colored_container {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        @include bp(xl) {
            height: 100%;
        }

        .db_content_wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: rem(40px) 0;

            @include bp(xl) {
                padding: rem(40px);
                align-items: flex-start;
                overflow-x: auto;
            }

            .db_icon_wrapper {
                position: relative;
                width: rem(125px);
                height: rem(55px);
                margin-bottom: rem(20px);
            }

            .db_mobile_bgimg {
                position: relative;
                margin-bottom: rem(20px);
                width: 100vw;
                height: rem(330px);

                @include bp(sm) {
                    width: 100%;
                }

                @include bp(xl) {
                    display: none;
                }
            }

            .db_title {
                text-align: center;

                @include bp(xl) {
                    text-align: left;
                }
            }

            .db_markdown > * {
                text-align: left;
            }
        }

        .db_over_image_container {
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 40%;

            @include bp(xl) {
                position: unset;
                display: block;
                height: 100%;
            }

            &_left {
                @include bp(xl) {
                    display: flex;
                    justify-content: flex-start;
                }
            }

            &_right {
                @include bp(xl) {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}
